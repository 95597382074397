import React, { useState } from 'react';
import './ShopList.less';
import Dashboard from './Dashboard';
import { Card, Row, Col, Form, Input, Select, Button, Table, Checkbox, Radio, Tooltip, message, Modal } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import productApi from '../servapi/product';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import shopApi from '../servapi/shop';

const AuthorizeShopOverlay = ({ visible, onCancel, onConfirm }) => {
  const [shopUrl, setShopUrl] = useState();
  const { t } = useTranslation();

  const handleConfirm = () => {
    onConfirm && onConfirm(shopUrl);
  }

  return (
    <Modal
      title={t("shopList.form.title")}
      centered
      visible={visible}
      onOk={handleConfirm}
      onCancel={onCancel}
    >
      <h3 style={{ fontSize: 32, textAlign: 'center'}}>{t("shopList.form.shopUrl")}</h3>
      <Form layout="vertical" className="hide-required-mark">
        <Form.Item>
          <Input value={shopUrl} placeholder='https://example.com 或 http://example.com' onChange={e => setShopUrl(e.target.value)}/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

const ShopsTable = () => {
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { t, i18n } = useTranslation();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 100,
  });


  React.useEffect(() => {
    fetchShops({ pagination })
  }, []);


  const fetchShops = async ({ pagination }) => {
    const pageData = await shopApi.getShops({
      page: pagination.current - 1,
      size: pagination.pageSize,
    })

    setData(pageData.shops || []);
    setPagination({
      ...pagination,
      current: pageData.number + 1,
      total: pageData.totalElements,
    })
  }

  const onToggleAllRows = (checked) => {
    const newSelectedRowKeys = checked ? data.map((record) => record.id.toString()) : [];
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onSelectChange = (record) => {
    const key = record.key;
    const selectedIndex = selectedRowKeys.indexOf(key);
    let newSelectedRowKeys = [...selectedRowKeys];

    if (selectedIndex === -1) {
      newSelectedRowKeys.push(key);
    } else {
      newSelectedRowKeys.splice(selectedIndex, 1);
    }

    setSelectedRowKeys(newSelectedRowKeys);
  };

  const columns = [
    {
      title: t("shopList.table.shop"),
      dataIndex: 'shopUrl',
      key: 'shopUrl',
      width: '25%',
    },
    {
      title: t("shopList.table.status"),
      dataIndex: 'status',
      key: 'status',
      width: '8%',
    },
    {
      title: t("shopList.table.updateTime"),
      dataIndex: 'updateTime',
      key: 'updateTime',
      width: '15%',
    },
    {
      title: t("shopList.table.action"),
      key: 'action',
      fixed: 'right',
      render: () => <>
        <a style={{ marginRight: 8 }}>{t("shopList.table.inspect")}</a>
        <a style={{ marginRight: 8 }}>{t("shopList.table.edit")}</a>
        <a>{t("shopList.table.unbind")}</a>
      </>,
      width: '10%',
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      // scroll={{ x: 'max-content' }}
      pagination={false}
      rowKey="id"
      className="shops-table"
    />
  )
}


export default props => {
  const { t } = useTranslation();
  const [authorizeShopVisiable, setAuthorizeShopVisiable] = useState(false);

  const handleConfirm = async (shopUrl) => {
    const redirect = await shopApi.startAuth(shopUrl);
    if (!redirect.redirectUrl) {
      return;
    }

    window.location.href = redirect.redirectUrl;
  }

  return (
    <>
      <div className="page_head">
        <div id="page_head_content" style={{ height: '72px' }}>
          <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
            <Col span={24}>
              <div className="flex-center-vertical">
                <h3 className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line text-24" style={{ margin: 0, lineHeight: '72px', minHeight: '72px', padding: '0px 8px', flexGrow: 1 }}>
                {t("shopList.title")}
                </h3>
                <Button type='primary' onClick={() => setAuthorizeShopVisiable(true)}>{t("shopList.btn.authorize")}</Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className='inner-content relative px-[16px]' style={{ paddingTop: '82px', paddingBottom: '32px' }}>
        <div className="saved-products">
          <Card className="shops-table mt-[16px]">
            <ShopsTable />
          </Card>
        </div>
        <AuthorizeShopOverlay visible={authorizeShopVisiable} onConfirm={handleConfirm} onCancel={() =>setAuthorizeShopVisiable(false)}/>
      </div>
    </>
  )
}