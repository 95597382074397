import React, { useState, useEffect } from 'react';
import { Table, Button, Drawer, Form, Input, Space, Breadcrumb, TreeSelect, Modal, message, Popconfirm } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import shopApi from '../../servapi/shop';
import categoryApi from '../../servapi/category';
import { useTranslation } from 'react-i18next';

const ShopCategoryForm = ({ visible, onClose, editingShopCategory, categories, onSave }) => {
  const [treeData, setTreeData] = useState([]);
  // const [selectedCategories, setSelectedCategories] = useState([1]);
  const { t } = useTranslation();

  useEffect(() => {
    // 构建树形数据结构
    const buildTree = (data, parentId = 0) => {
      return data
        .filter(category => category.parentCategoryId === parentId)
        .map(category => ({
          title: category.description?.name,
          value: category.id,
          key: category.id,
          children: buildTree(data, category.id),
        }));
    };

    const treeStructure = buildTree(categories);
    setTreeData(treeStructure);
  }, [categories]);

  // 递归查找父类目ID列表
  const findAllParentIds = (categoryId, categoryList) => {
    const category = categoryList.find(cat => cat.id === categoryId);
    if (category && category.parentCategoryId !== 0) {
      return [category.parentCategoryId, ...findAllParentIds(category.parentCategoryId, categoryList)];
    }
    return [];
  };


  return (
    <Modal
      title={editingShopCategory ? t('manageShop.category.form.editTitle') : t('manageShop.category.form.createTitle')}
      visible={visible}
      onCancel={onClose}
      centered
      footer={null}
      width={520}
    >
      <Form onFinish={onSave} initialValues={editingShopCategory || {}} key={editingShopCategory}>
        <Form.Item name="name" label={t('manageShop.category.form.name')} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="slug" label={t('manageShop.category.form.slug')}  rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="categoryIds" label={t('manageShop.category.form.categorySelect')}  rules={[{ required: false }]}>
          <TreeSelect
            treeData={treeData}
            showCheckedStrategy={TreeSelect.SHOW_ALL}
            treeCheckable
            treeCheckStrictly
            placeholder={t('manageShop.category.form.relateCategory')}
            allowClear
            showSearch
            treeDefaultExpandAll
            // onChange={handleCategoryChange} // 使用自定义的 check 处理逻辑
            style={{ width: '100%' }}
            filterTreeNode={(inputValue, treeNode) => {
              if (treeNode && treeNode.title) {
                return treeNode.title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
              }

              return false;
            }

            }
          />
        </Form.Item>
        <Form.Item name="description" label={t('manageShop.category.form.desc')}>
          <Input.TextArea />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          {t('manageShop.category.form.save')}
        </Button>
      </Form>
    </Modal>

  );
};

const ShopProductCategoryManager = ({ shopId = 2 }) => {
  const [shopCategories, setShopCategories] = useState([]);
  const [visible, setVisible] = useState(false);
  const [editingShopCategory, setEditingShopCategory] = useState(null);
  const [parentId, setParentId] = useState(); // From route parameters
  const [categoryPaths, setCategoryPaths] = useState([]);
  const [categories, setCategories] = useState([]);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    // Fetch shop categories based on parentId
    fetchShopCategories(shopId, parentId);
    fetchCategoryPaths(shopId, parentId);
  }, [shopId, parentId]);

  useEffect(() => {
    categoryApi.getAllCategories()
      .then(setCategories)
      .catch(console.log);
  }, [i18n.language])

  const fetchShopCategories = async (shopId, parentId) => {
    const categories = await shopApi.getShopCategories(shopId, { parentId });
    setShopCategories(categories);
  }

  const fetchCategoryPaths = async (shopId, parentId) => {
    if (!parentId) {
      setCategoryPaths([]);
      return;
    }

    const paths = [];
    let parentCategory;
    while (parentId && parentId > 0) {
      parentCategory = await shopApi.getShopCategory(parentId);
      paths.push(parentCategory);
      parentId = parentCategory.parentId;
    }

    setCategoryPaths(paths.reverse());
  }

  const showDrawer = (shopCategory = null) => {
    if (shopCategory != null) {
      setEditingShopCategory({
        id: shopCategory.id,
        slug: shopCategory.code,
        name: shopCategory.description?.name,
        categoryIds: shopCategory.categoryIds,
      });
    } else {
      setEditingShopCategory(null);
    }
    setVisible(true);
  };

  const handleSave = async (values) => {
    let parentId;
    const categoryIds = (values.categoryIds || []).map(c => c.value);

    if (categoryPaths.length > 0) {
      parentId = categoryPaths[categoryPaths.length - 1].id;
    }

    if (editingShopCategory) {
      await shopApi.updateShopCategory(shopId, editingShopCategory.id, {
        ...editingShopCategory,
        ...values,
        categoryIds,
      });
    } else {
      await shopApi.createShopCategory(shopId, {
        ...values,
        categoryIds,
        parentId,
      });
    }
    handleDrawerClose();
    setEditingShopCategory(null);
    fetchShopCategories(shopId, parentId);
  };

  // Dynamically generate page title and operations based on parentId

  const handleSetParentId = record => {
    setParentId(record.id);
  }

  const handleDelete = async record => {
    const hide = message.loading(t('manageShop.message.beingDeleteCategory'), 0);
    const resp = await shopApi.deleteShopCategory(shopId, record.id);
    console.log(resp);
    hide();
    message.success(t('manageShop.message.deletedCategory'));
    fetchShopCategories(shopId, parentId);
  }

  console.log(shopCategories, 'categories...');

  const columns = [
    {
      title: t('manageShop.category.table.name'),
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (<span>{record.description?.name}</span>)
    },
    {
      title: t('manageShop.category.table.slug'),
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: t('manageShop.category.table.status'),
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => <span>{record.wpProductCategoryId ? t('manageShop.category.table.synced') : t('manageShop.category.table.waitSync')}</span>
    },
    {
      title: t('manageShop.category.table.action'),
      render: (_, record) => (
        <>
          <Button type="link" onClick={() => showDrawer(record)}> {t('manageShop.category.table.edit')}</Button>
          <Popconfirm
            title={t('manageShop.message.confirmDeleteCategory')}
            onConfirm={() => handleDelete(record)} // 替换为实际删除逻辑
            okText={t("common.confirm.ok")}
            cancelText={t("common.confirm.cancel")}
          >
            <Button type="link">
              {t('manageShop.category.table.delete')}
            </Button>
          </Popconfirm>
          {
            record.depth < 3 && (
              <Button type="link" onClick={() => handleSetParentId(record)}>
                {t('manageShop.category.table.inspect')}
              </Button>
            )
          }
        </>
      ),
    },
  ];

  const handleDrawerClose = () => {
    setVisible(false);
    setEditingShopCategory(null);
  }

  const handleResyncShopCategories = async () => {
    const hide = message.loading(t('manageShop.message.beingSyncCategory'), 0);
    const resp = await shopApi.resyncShopCategories(shopId);
    hide();
    message.success(t('manageShop.message.syncedCategory'));

    fetchShopCategories(shopId, parentId)
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', marginBottom: 16, marginTop: 24 }}>
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => setParentId(null)}>
            <span className="cursor-pointer">
              <HomeOutlined />
            </span>
          </Breadcrumb.Item>
          {
            categoryPaths.reverse().map(categoryPath => (
              <Breadcrumb.Item key={categoryPath.id} onClick={() => setParentId(categoryPath.id)}>
                <span>{categoryPath.description?.name}</span>
              </Breadcrumb.Item>
            ))
          }
        </Breadcrumb>
        <div>
          <Button type="primary" onClick={handleResyncShopCategories} style={{ marginRight: 8 }}>{t('manageShop.category.btn.sync')}</Button>
          <Button type="primary" onClick={() => showDrawer()}>{t('manageShop.category.btn.create')}</Button>
        </div>
      </div>
      <Table columns={columns} dataSource={shopCategories || []} rowKey="id" />
      {
        visible && <ShopCategoryForm visible={visible} editingShopCategory={editingShopCategory} categories={categories} onSave={handleSave} onClose={() => setVisible(false)} />
      }
    </div>
  );
};

export default ShopProductCategoryManager;
