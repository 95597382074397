import React, { useEffect, useState } from 'react';
import { Form, Switch, InputNumber, Button, Card, Table, Empty, Input, Select, Checkbox, message } from 'antd';
import shopApi from '../../servapi/shop';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const SettingsForm = ({ shopId }) => {
  const [shop, setShop] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (!shopId) {
      return;
    }

    shopApi.getShop(shopId)
      .then(setShop)
      .catch(console.log)
  }, [shopId])

  const handleSave = async () => {
    const hide = message.loading(t("manageShop.message.begingUpdateSetting"), 0);
    const resp = await shopApi.updateShopConfig(shopId, shop);
    console.log(resp);
    hide();
    message.success(t("manageShop.message.updatedSetting"));
  }
  
  return (
    <Form layout="horizontal">
      <Card title={t("manageShop.general.basic.title")} bordered style={{ marginBottom: 24 }}>
        <Form.Item label={t("manageShop.general.basic.shopName.label")}>
          <Input style={{ width: 220 }} value={shop.shopName} onChange={e => setShop({ ...shop, shopName: e.target.value })}/>
        </Form.Item>
        <Form.Item label={t("manageShop.general.basic.language.label")}>
          <Select style={{ width: 220 }} value={shop.language} onChange={value => setShop({ ...shop, language: value })}>
            <Option value="cn">{t("manageShop.general.basic.language.options.cn")}</Option>
            <Option value="en">{t("manageShop.general.basic.language.options.en")}</Option>  
            <Option value="ru">{t("manageShop.general.basic.language.options.ru")}</Option>  
            <Option value="es">{t("manageShop.general.basic.language.options.es")}</Option>              
          </Select>
        </Form.Item>
        {/* <div>
          <span>当采购商品出现溢价时，实际购买价格不高于原价</span>
          <Form.Item>
            <InputNumber id="premiumRate" defaultValue={10} addonAfter="%" min={0} max={1000} />
          </Form.Item>
        </div> */}
      </Card>

      <Card title={t("manageShop.general.currencyRate.title")} style={{ marginBottom: 24 }}>
        <p>
          {t("manageShop.general.currencyRate.desc")}
          <a href="https://www.x-rates.com/calculator/?from=USD&to=CNY&amount=1" target="_blank" rel="noopener noreferrer">{t("manageShop.general.currencyRate.query")}</a>
        </p>
        <label>{t("manageShop.general.currencyRate.currency.label")}</label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Select style={{ width: 120 }} value={shop.currency} onChange={value => setShop({ ...shop, currency: value })}>
            <Select.Option value="USD">USD</Select.Option>
            <Select.Option value="CNY">CNY</Select.Option>
            <Select.Option value="EUR">EUR</Select.Option>
            <Select.Option value="ZAR">ZAR</Select.Option>
            {/* 其他币种选项 */}
          </Select>
          <div class="line-height-form-item-control ml-10 mr-10"><span role="img" aria-label="swap" class="anticon anticon-swap"><svg viewBox="64 64 896 896" focusable="false" data-icon="swap" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M847.9 592H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h605.2L612.9 851c-4.1 5.2-.4 13 6.3 13h72.5c4.9 0 9.5-2.2 12.6-6.1l168.8-214.1c16.5-21 1.6-51.8-25.2-51.8zM872 356H266.8l144.3-183c4.1-5.2.4-13-6.3-13h-72.5c-4.9 0-9.5 2.2-12.6 6.1L150.9 380.2c-16.5 21-1.6 51.8 25.1 51.8h696c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path></svg></span></div>
          <Select style={{ width: 120 }} defaultValue="CNY" disabled>
            <Select.Option value="USD">USD</Select.Option>
            <Select.Option value="CNY">CNY</Select.Option>
            {/* 其他币种选项 */}
          </Select>
          <InputNumber
            style={{ marginLeft: 10, width: 100 }}
            value={shop.exchangeRate}
            onChange={value => setShop({ ...shop, exchangeRate: value })}
            min={0}
            step={0.01}
          />
        </div>
        {/* <div style={{ marginTop: 10 }}>
          <Checkbox>
            查询商品币种与店铺默认一致
          </Checkbox>
        </div> */}
      </Card>
      <Card title={t("manageShop.general.priceRate.title")} style={{ marginBottom: 24 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {t("manageShop.general.priceRate.desc1")}
          <InputNumber
            style={{ marginLeft: 10, marginRight: 10, width: 100 }}
            value={shop.priceRate * 100}
            onChange={value => setShop({ ...shop, priceRate: value / 100 })}
            min={0}
            max={100}
            step={1}
            suffix="%"
          /> {t("manageShop.general.priceRate.desc2")}
        </div>
        {/* <div style={{ marginTop: 10 }}>
          <Checkbox>
            查询商品币种与店铺默认一致
          </Checkbox>
        </div> */}
      </Card>
      <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
        <Button type="primary" htmlType='button' style={{ marginTop: 10 }} onClick={handleSave}>{t("manageShop.general.btn.save")}</Button>
      </div>
    </Form>
  );
};

export default SettingsForm;
